.flex{
    display: flex;
}

.ml{
    margin-left: 1rem;
}

.mr{
    margin-right: 1rem;
}

.mr-half{
    margin-right: 0.5rem;
}

.mb{
    margin-bottom: 1rem;
}

.mt{
    margin-top: 1rem;
}

.relative {
    position: relative;
}