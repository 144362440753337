/* Estilos antes de cargar un archivo */
.filepond--root {
    font-family: 'Arial', sans-serif;
    border-radius: 8px;
    font-size: 12px
  }
  
  .filepond--panel-root {
    background-color: #f8f8f8;
    border: 2px dashed #d8d8d8;
  
  }
  
  .filepond--label-action {
    color: #007bff;
    text-decoration: underline;
  
  }
  
  /* Estilos después de cargar un archivo */
  .filepond--item {
    border-radius: 8px;
    background-color: #f0f0f0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
  }
  
  .filepond--item-panel {
    background-color: rgb(36, 36, 36);
  ;
  }
  
  .filepond--action-retry-item-processing {
    background-color: #007bff;
    color: #ffffff;
  
  }
  